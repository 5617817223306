.root {
}

.card-integration {
  padding: 25px;
}

.code-wrapper {
  background-color: rgb(248, 248, 255);
  padding-bottom: 20px;
  position: relative;
}

.code-wrapper button {
  position: absolute;
  right: 15px;
  bottom: 10px;
  background-color: lightgray;
  color: white;
  font-weight: bold;
  border: none;
  padding: 7px 10px;
  border-radius: 5px;
}

.code-wrapper button:hover {
  cursor: pointer;
}

.code-wrapper pre {
  padding: 0px 20px 15px !important;
}

.gtm-code {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.gtm-code-inner {
  display: flex;
  align-items: center;
  background-color: rgb(248, 248, 255);
  flex: 1;
  justify-content: space-between;
  padding-right: 15px;
}

.gtm-code-inner button {
  background-color: lightgray;
  color: white;
  font-weight: bold;
  border: none;
  padding: 7px 10px;
  border-radius: 5px;
  height: 30px;
}
.gtm-code-inner button:hover {
  cursor: pointer;
}

.learn-more {
  margin-left: 10px;
  font-weight: 400;
  color: #5569ff;
}

.learn-more:hover {
  cursor: pointer;
}

.ga-integration {
  margin-top: 15px;
  padding: 20px;
}
