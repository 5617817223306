body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: auto !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.a-mail {
  text-decoration: none !important;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.dropdown-class-maui .MuiMenuItem-root {
  display: flex !important;
  justify-content: space-between !important;
  min-width: 200px !important;
}

.dropdown-class-maui .MuiListItemIcon-root {
  min-width: 25px;
}

.dropdown-class-maui .MuiAvatar-root {
  font-size: small;
}

.img-featured-list-preview {
  max-width: 175px;
}

.email-verification, .maintenance-notice {
  color: #e92323;
}
