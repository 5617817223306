.dashboard {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.sticky-left-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  min-width: 300px;
  height: 300px;
}

.summary-data {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.summary-filter-wrapper {
  margin: 25px 0px 20px 0px;
}

.filters-wrapper {
  display: flex;
}
