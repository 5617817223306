// @import './reset';
.rst__placeholder::before {
  border: 1px dashed #dcdcdc;
  border-radius: 6px;
  z-index: 1;
}

.rst__rowContents {
  // border-radius: 0 8px 8px 0;
  padding: 0;
  width: 50px;
}

.rst__rowLabel {
  width: 60%;
  padding-right: 3px;
  & input {
    //width: 90%;
  }
}

.rst__row {
  height: 54px;
}

.rst__moveHandle,
.rst__loadingHandle {
  border-radius: 8px 0 0 8px;
  box-shadow: none;
}

.rst__rowContents {
  box-shadow: none;
  // border-color: #d2d2d2;
  border: none;
  border-radius: 8px;
}

.rst__rowTitle {
  display: flex;
  align-items: center;
}

.rst__rowToolbar {
  display: flex;
}

.rst__rowToolbar:after {
  content: '';
  background: lightgrey;
  position: absolute;
  height: 50%;
  width: 1px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  background-color: #8e8e8e;
}

.rst__moveHandle {
  border: none;
  width: 35px;
}

.rst__toolbarButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 54px;
  cursor: pointer;
  &:nth-child(3) {
    border-radius: 0 10px 10px 0;
  }
  &:only-child {
    border-radius: 0 10px 10px 0;
  }
  &:hover {
    background-color: #eef0ff;
  }
  img {
    width: 18px;
    height: 18px;
  }
  &:first-child {
    width: 35px;
  }
}

.container {
  max-width: 100%;
}

.circular-progress-center {
  margin-top: 25%;
  margin-left: 48%;
}

.clickable-elem:hover {
  cursor: pointer;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #5454ff !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: #5454ff0d !important;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.css-cnms5y-MuiContainer-root {
  max-width: unset !important;
  width: 95% !important;
  margin: 0 !important;
}

@media (max-width: 1279px) {
  .container {
    margin-left: 0;
  }
}

.step-buttons {
  display: grid !important;
  grid-template-columns: 20% 60% 20%;
  margin-top: 25px;
}

.align-progressBar {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.single-sequence-poll-card {
  box-sizing: border-box;
}

.MuiCardActions-spacing:hover {
  cursor: pointer;
}

.form-html-check-help {
  margin-top: 20px;
}

.tabs {
  margin-top: 20px;
}

.sortButton {
  margin-top: 20px;
}
.rst__row {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.nodeActive {
  // -webkit-box-sizing: border-box;
  // -moz-box-sizing: border-box;
  // box-sizing: border-box;
  box-sizing: content-box;
  border: 2px solid #5454ff !important;
  border-radius: 10px;
  z-index: 999;
  .rst__moveHandle {
    background-color: #5454ff;
  }
}

.editor-sidebar {
  position: fixed;
  right: 0;
  overflow-y: scroll;
  height: 100%;
}

.editor-buttons  {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor-buttons button {
}

.css-wgidp1 {
  margin: 20px 0px 20px 10px !important;
}

.logo {
  height: 65px;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px !important;
}

.dialog-title span {
  font-size: 16px !important;
}

.dialog-title h2 {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.empty-list-experience {
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.empty-list-experience img {
  width: 30%;
}

.home-buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
}

.home-buttons-wrapper button {
  min-width: 200px;
}

.widget-form-settings {
  display: flex;
  margin-bottom: 50px;
}

.home-widget-settings {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
}

.widget-form-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3;
}

.home-buttons-wrapper-settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  margin-top: 30px;
}

.home-buttons-wrapper-settings button {
  min-width: 150px;
}

.widget-settings-checkbox-wrapper {
  flex: 2;
}

.home-form-wrapper,
.overlay-url-wrapper {
  width: 88%;
}

.home-form-wrapper p,
.overlay-url-wrapper p {
  margin-left: 10px;
}

.home-widget__inline-input-checkbox {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.home-widget__inline-input-checkbox div {
  flex: 2;
}

.home-widget__urls button {
  max-width: 25px;
  margin-top: 0px;
}

.home-widget-settings-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-widget-checkbox-status {
  display: flex;
}

.widget-settings {
  &__enabled-checkbox {
    display: flex;
    align-items: center;
  }
}

.home-step-one-form {
  display: grid;
  grid-template-columns: 4fr 3fr;
  justify-content: center;
  margin-top: 16px;
}

.step-one-image {
  justify-self: flex-start;
  align-self: flex-start;
}
