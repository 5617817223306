.single-sp-overview {
  width: calc(100% - 300px);
}

.analytics-header {
  display: flex;
  justify-content: space-between;
}

.analytics-header .close-wrapper {
  padding: 30px 35px 0px 0px;
  color: grey;
}

.analytics-header .close-wrapper:hover {
  cursor: pointer;
}
