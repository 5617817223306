.accountCard {
  margin: 15px 15px 25px 15px;
}

.accountCard .header h4 {
  background-color: #f6f8fb;
  padding: 20px;
  margin: 0px;
}

.accountCard ul {
  padding: 10px 20px;
}

.accountCard li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  padding: 10px 0px;
}
