.MuiPaper-root.summary-table {
  overflow: clip;
  height: 100%;
}

.summary-table table {
  position: relative;
  width: 100%;
}

.summary-table table tr {
  min-height: 75px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f6f8fb;
}

.summary-table table tr.Compact td {
  font-size: 14px;
}

.summary-table tbody tr {
  padding: 0px 10px;
}

.summary-table thead {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.summary-table thead tr {
  height: 50px;
}

.summary-table thead th {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.summary-table thead tr th:first-child {
  /* font-size: 18px; */
}

.summary-table-header-info {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
}

.summary-table-header-info {
}

.summary-table thead tr th {
  color: rgb(128, 128, 128);
}

.summary-table table td {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.summary-table table td:not(:first-child) {
  justify-content: center;
}

.summary-table-header {
  background-color: #f6f8fb;
  margin: 0;
  min-height: 75px;
  display: flex;

  align-items: center;
}

/* .sticky-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
} */

.table-avatar {
  min-width: 45px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.table-avatar-square {
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: 10px;
  margin-right: 10px;
  object-fit: cover;
}

.summary-table a {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.summary-table-clickable span {
  max-width: 75px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.summary-table-clickable:hover {
  cursor: pointer;
}

.summary-table-wrapper {
  width: 100%;
}

.sticky-header {
  position: sticky;
  top: 0px;
}

.table-show-more {
  height: 50px !important;
  color: rgba(128, 128, 128, 0.718);
}


.table-close {
  position: absolute;
  right: 30px;
  color: rgba(57, 57, 57, 0.718);
  top: 8px;
}

.table-show-more td:hover,
.table-close:hover {
  cursor: pointer;
}

/* TODO: that's Temporary media query, need to use MUI */
@media only screen and (max-width: 991px){
  .summary-table table tr {
    height: auto;
  }
  .summary-table table td {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .table-close {
    right: 3px;
  }
}