@import '../../styles/variables';
@import '../../styles/animations';

.sequenceDemo {
  min-height: 580px;
  background: #f8f8f8;
  padding: 5px 25px 5px;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  height: 100%;
  position: relative;
}

.sequenceDemoPreview {
  position: relative;
  width: 100%;
  height: 140px;
}

.mobileBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  color: black;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  @extend .center-center;
  &__header {
    background-color: #fff;
    img {
      width: 120px;
    }
  }

  &__images {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    text-align: center;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__video__loader {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 85%;
  }
  &__video {
    padding: 25px;
    text-align: center;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    text-align: center;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 44%;
  }
  &__video {
    padding: 0px;
    text-align: center;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    position: relative;
    height: 100%;
    background-color: #eaeaea;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      animation: fadeIn 0.5s ease 1;
    }
  }

  &.hasTitle {
    grid-template-rows: minmax(40px, max-content) auto;
    .image {
      height: 100%;
      overflow: hidden;
    }
  }

  &.hasVideoOrImage {
    grid-template-rows: 40% 60%;
    .image {
      height: 100%;
      overflow: hidden;
    }

    &.hasSixItems {
      .image {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

.imageSingle {
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: fadeIn 0.5s ease 1;
  }
}

.directLinkImage {
  cursor: pointer;
}

.directLinkWrapper {
  position: relative;
  background-color: black;
}

.directLinkLabel {
  font-family: 'Segoe UI';
  position: absolute;
  top: 50%;
  color: white;
  font-size: 25px;
  z-index: 500;
}

.fileInput {
  display: none;
}

.headingImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

//////////////////////
.splitS {
  height: 100%;
}

.splitOne,
.splitTwo,
.splitThree,
.splitFour,
.splitFive,
.splitSix {
  height: 100%;
  overflow: hidden;
}
.splitOne {
  grid-template-rows: 100%;
}

.splitTwo {
  display: grid;
  gap: 0.5%;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  .image {
    height: 100%;
  }
}
.imageWrapper {
  position: absolute;
  height: 100%;
  top: 0;
  overflow: hidden;
  width: 100%;
}

.splitThree {
  display: grid;
  gap: 0.5%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  .image {
    &:first-of-type {
      grid-column-end: 5;
      grid-column-start: 1;
    }
    &:nth-of-type(2) {
      grid-column-end: 3;
      grid-column-start: 1;
    }
    &:nth-of-type(3) {
      grid-column-end: 5;
      grid-column-start: 3;
    }
  }
}

.splitFour {
  display: grid;
  gap: 0.5%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.splitFive {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0.5%;
  .image {
    &:nth-of-type(1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &:nth-of-type(2) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &:nth-of-type(3) {
      grid-column-start: 2;
      grid-column-end: 3;
    }
    &:nth-of-type(4) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &:nth-of-type(5) {
      grid-column-start: 2;
      grid-column-end: 2;
    }
  }
}
.splitOdd ,
  .splitEven{
  grid-template-rows: auto;
  overflow-y: scroll;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* .splitOdd::-webkit-scrollbar,
.splitEven::-webkit-scrollbar{
  display: none;
} */

.splitSix {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 0.5%;
}
// Grid preview styles if video/image available
.hasVideoOrImage {
  .splitThree {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    .image {
      &:first-of-type {
        grid-column-end: 5;
        grid-column-start: 1;
      }
      &:nth-of-type(2) {
        grid-column-end: 3;
        grid-column-start: 1;
      }
      &:nth-of-type(3) {
        grid-column-end: 5;
        grid-column-start: 3;
      }
    }
  }
  .splitOdd,
  .splitEven {
    .image {
      min-height: 100px;
    }
  }
}
