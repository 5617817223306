.mainBox {
  background: #f8f8f8;
  padding: 25px 25px 70px;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  height: 500px;
  position: relative;
}

.center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
