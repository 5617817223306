.step-one-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  // margin-left: 40px;
}
.step-one-brand-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: auto;
  width: 70%;
  justify-content: center;
  height: 100%;
}

.step-one-image img {
  width: 80%;
}

.clickable-elem {
  color: black;
}

.step-one-brand-form {
  width: 80%;
}

.ai-features-heading {
  margin: 10px;
  vertical-align: super;
}
