.overlay-url-wrapper {
  padding: 0px 10px !important;
}

.overlay-url-wrapper hr {
  width: 100% !important;
}

.overlay-settings-wrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}

.template-settings-wrapper {
  width: 88%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}

.device-type-settings-wrapper {
  width: 88%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}
