.recentlyEditedCard {
  margin: 15px;
}

.recentlyEditedCard .header h4 {
  background-color: #f6f8fb;
  padding: 20px;
  margin: 0px;
}

.recentlyEditedCard ul {
  padding: 0px 20px;
  list-style: none;
}

.recentlyEditedCard li {
  display: flex;
  align-items: center;
}

.round-avatar {
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 10px;
  object-fit: cover;
}

.summary-recently-poll-item:hover {
  cursor: pointer;
}
