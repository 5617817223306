.sidebarBox {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;
}

.siderbarPrview {
  @extend .sidebarBox;
  height: 200px;
}

.paperWrapper {
  padding: 10px 8px;
}

.sequenceOptions {
  margin-right: 18px;
  margin-top: 45px;
}

.sidebarTitle {
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}

.fullWidth {
  width: 100%;
}

.cardOption {
  margin-top: 15px;
}

input[type='color'] {
  -webkit-appearance: none;
  border: none;
  padding: 2px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  border-radius: 5px;
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 3px;
}

.pickerSlider {
  display: flex;
}

.pickerSlider > input[type='color'] {
  margin-right: 10px;
  margin-left: 5px;
}

.cardEditorTooltips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 5px;
}

.cardEditorTooltips button,
.cardEditorTooltips i {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 800;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  font-family: monospace;
}

.cardEditorTooltips button:hover {
  cursor: pointer;
}

.buttonStyleActive {
  color: #1472cf;
}

.italicButton {
  font-style: italic;
}
