.DateRangePicker__CalendarSelection {
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
}
/*.DateRangePicker__CalendarSelection--is-pending {*/
/*    background-color: rgba(52, 237, 237, 0.75);*/
/*}*/
/*.DateRangePicker__LegendItemColor--selection {*/
/*    background-color: #d434ed;*/
/*}*/
.DateRangePicker__CalendarHighlight--single {
    border: 1px solid #0d6efd;
}
.DateRangePicker__MonthHeaderSelect {
    background: #ffffff;
}